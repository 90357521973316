// DynamicTextSection.tsx
import React from 'react';
import "./DynamicTextSection.css"

interface DynamicTextSectionStyles {
    containerStyle?: React.CSSProperties;
    headingStyle?: React.CSSProperties;
    firstFamilyStyle?: React.CSSProperties;
    secondFamilyStyle?: React.CSSProperties;
}

interface DynamicTextSectionProps {
    heading: string,
    content: { parent1: string; parent2: string}[];
    styles?: DynamicTextSectionStyles
}

const DynamicTextSection: React.FC<DynamicTextSectionProps> = ({ heading, content, styles}) => {
    return (
        <p className="dynamic-text-header" style={styles?.headingStyle}>{heading}
            <section className="dynamic-text-section">
                {content.map((item, index) => (
                    <div key={index} className="dynamic-text-container" style={styles?.containerStyle}>
                        <div className="dynamic-text-item">
                            <p style={styles?.firstFamilyStyle}>{item.parent1}</p>
                            <p style={styles?.secondFamilyStyle}>{item.parent2}</p>
                        </div>
                    </div>
                ))}
            </section>
        </p>
    );
};

export default DynamicTextSection;
