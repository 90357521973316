// src/components/WeddingText.tsx
import React from "react";
import "./WeddingText.css";

interface WeddingTextStyles {
    headingStyle?: React.CSSProperties;
    subtitleStyle?: React.CSSProperties;
    sectionStyle?: React.CSSProperties;
}

interface WeddingTextProps {
    id: string;
    heading: string;
    subtitle: string;
    styles?: WeddingTextStyles;
}

const WeddingText: React.FC<WeddingTextProps> = ({id, heading, subtitle, styles}) => {

    return (
        <section id={id} className="wedding-text-section" style={styles?.sectionStyle}>
            <div className="wedding-text-container">
                <h1 className="wedding-text-heading" style={styles?.headingStyle}>
                    {heading}
                </h1>
                <p className="wedding-text-subtitle" style={styles?.subtitleStyle}>
                    {subtitle}
                </p>
            </div>
        </section>
    );
};

export default WeddingText;
