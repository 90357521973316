// src/components/NavigationBar.tsx
import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./NavigationBar.css";

interface NavigationBarStyles {
    navBarColor?: React.CSSProperties;
    navBarActiveColor?: React.CSSProperties;
    navBarItemPersonalized?: React.CSSProperties;
}

interface NavigationBarProps {
    pageNavigationLinks: { to: string; label: string }[];
    scrollToSectionLinks: { to: string; label: string }[];
    logoSrc: string;
    styles?: NavigationBarStyles;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ pageNavigationLinks, scrollToSectionLinks, logoSrc, styles }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleScrollToSection = (targetId: string) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            const yOffset = -60; // Adjust the offset as needed
            const y =
                targetElement.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;

            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    useEffect(() => {
        // Check if the URL contains a section anchor (e.g., #section1)
        const hash = window.location.hash;
        if (hash) {
            const targetId = hash.substring(1); // Remove the '#' from the hash
            handleScrollToSection(targetId);
        }
    }, []);

    // Scroll to the top when a link is clicked
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                // Adjust the threshold (100 in this example) as needed
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (

        <nav
            className={`navbar ${menuOpen ? "open" : ""}`}
            style={scrolling || menuOpen ? styles?.navBarColor : { backgroundColor: 'transparent' }}
        >
            <button className="menu-button" onClick={toggleMenu}>
                <div className={`hamburger ${menuOpen ? "active" : ""}`}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </button>
            <ul className={`navbar-list ${menuOpen ? "open" : ""}`}>
                {pageNavigationLinks.map((link, index) => (
                    <li
                        className={`navbar-item ${location.pathname === link.to ? "active" : ""}`}
                        style={location.pathname === link.to ? styles?.navBarActiveColor : { background: '' }}
                    >
                        <div className="navbar-item-element">
                            <NavLink
                                to={link.to}
                                key={index}
                                onClick={scrollToTop}
                                style={styles?.navBarItemPersonalized}
                            >
                                {link.label}
                            </NavLink>
                        </div>
                    </li>
                ))}
                {scrollToSectionLinks.map((link, index) => (
                    <li
                        className="navbar-item"
                    >
                        <div className="navbar-item-element">
                            <Link
                                to={link.to}
                                key={index}
                                className="navbar-item"
                                onClick={() => handleScrollToSection(link.to.substring(1))} // Remove the '#' from the link
                                style={styles?.navBarItemPersonalized}
                            >
                                {link.label}
                            </Link>
                        </div>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavigationBar;
