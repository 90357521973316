// ImageSection.tsx
import React from 'react';
import "./ImageSection.css"

interface ImageSectionStyle {
    imageStyle?: React.CSSProperties;
}

interface ImageSectionProps {
  backgroundImage: string;
  style?: ImageSectionStyle;
}

const ImageSection: React.FC<ImageSectionProps> = ({backgroundImage, style}) => {
    const sectionBackground = {
        backgroundImage: `url(${backgroundImage})`,
      };

  return (
    <section className="image-section" style={sectionBackground}>
    </section>
  );
};

export default ImageSection;
