import React from "react";
import NavigationBar from "../../../components/NavigationBar/NavigationBar";
import HeroSection from "../../../components/HeroSection/HeroSection";
import FooterBar from "../../../components/FooterBar/FooterBar";
import "../../../global_styles/RSVP.css";

const RSVP: React.FC = () => {
    //HeroSection
    const customBackgroundImage = require("../../../images/weddings/JesusValeriaWedding/image10.jpeg");
    const customHeading = "RSVP";
    const customSubheading = "";
    //NavigationBar
    const navigationLinks = [
        { to: "/JesusValeriaWedding/", label: "Inicio" },
        { to: "/JesusValeriaWedding/RSVP/", label: "RSVP" },
    ];
    const customNavigationStyles = {
        navBarColor: {
            backgroundColor: "#8d99ae",
        },
        navBarActiveColor: {
            backgroundColor: "rgb(255,255,255,0.3)",
        },
        navBarItemPersonalized: {
            color: "white",
            textShadow: "-1px 1px 8px rgba(0, 0, 0, 0.4)"
        }
    };
    return (
        <div className="RSVP-page">
            <main>
                <NavigationBar
                    pageNavigationLinks={navigationLinks}
                    scrollToSectionLinks={[]}
                    logoSrc=""
                    styles={customNavigationStyles}
                />
                <HeroSection
                    backgroundImage={customBackgroundImage}
                    heading={customHeading}
                    subheading={customSubheading}
                />
                <section className="google-form-section">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSc_Kp9R_O8VWMJ3amEqONkLI22UoR3bALQZ4y541AthTmJ-Ag/viewform?usp=pp_url&entry.877086558=S%C3%AD,+asistir%C3%A9&entry.2032437645=No"
                        title="google form invitation"
                    ></iframe>
                </section>
            </main>
            <FooterBar />
        </div>
    );
};

export default RSVP;
