// src/components/LocationSection.tsx
import React, { useState } from "react";
import "./LocationSection.css";

interface LocationSectionStyle {
    locationHeadingColor?: React.CSSProperties;
    locationTimeColor?: React.CSSProperties;
    locationButtonColor?: React.CSSProperties;
}

interface LocationSectionProps {
  images: string[];
  header: string[];
  time: string[];
  subtext: string[];
  links: string[];
  style?: LocationSectionStyle;
}

const LocationSection: React.FC<LocationSectionProps> = ({ images, header, time, subtext, links, style}) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  const openPopup = (index: number) => {
    setPopupVisible(true);
    setSelectedImageIndex(index);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedImageIndex(null);
  };
  return (
    <section className="location-section">
      {images.map((image, index) => (
        <div className="location-container" key={index}>
          <div className="location-picture-text">
            <img src={image} alt={`Image ${index + 1}`} />
            <h1 style={style?.locationHeadingColor}>{header[index]}</h1>
            <a  style={style?.locationTimeColor}>{time[index]}</a>
            <p>{subtext[index]}</p>
            <div className="popup-button-container">
            <button style={style?.locationButtonColor}  className="open-popup-button" onClick={() => openPopup(index)}>
                Ver mapa
              </button>
            </div>
          </div>
        </div>
      ))}
      {popupVisible && selectedImageIndex !== null && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-button" onClick={closePopup}>
              Cerrar
            </button>
            <iframe
              src={links[selectedImageIndex]}
              title={`Embedded Content ${selectedImageIndex}`}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default LocationSection;
