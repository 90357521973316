import React from "react";
import NavigationBar from "../../../components/NavigationBar/NavigationBar";
import HeroSection from "../../../components/HeroSection/HeroSection";
import FooterBar from "../../../components/FooterBar/FooterBar";
import "../../../global_styles/RSVP.css";

const RSVP: React.FC = () => {
    //HeroSection
    const customBackgroundImage = require("../../../images/weddings/KarenJerryWedding/image4.jpeg");
    const customHeading = "RSVP";
    const customSubheading = "";
    const customHeroStyle = {
        heroHeadingStyle: {
            fontFamily: "Hailey",
        },
        heroSubtextStyle: {
            fontFamily: "didot"
        },
        heroClockStyle: {
            fontFamily: "didot",
        }
    };
    //NavigationBar
    const navigationLinks = [
        { to: "/KarenJerryWedding/", label: "Inicio" },
        { to: "/KarenJerryWedding/RSVP/", label: "RSVP" },
    ];
    const customNavigationStyles = {
        navBarColor: {
            backgroundColor: "#765830",
        },
        navBarActiveColor: {
            backgroundColor: "rgb(255,255,255,0.3)",
        },
        navBarItemPersonalized: {
            color: "white",
            textShadow: "-1px 1px 8px rgba(0, 0, 0, 0.4)"
        }
    };
    return (
        <div className="RSVP-page">
            <main>
                <NavigationBar
                    pageNavigationLinks={navigationLinks}
                    scrollToSectionLinks={[]}
                    logoSrc=""
                    styles={customNavigationStyles}
                />
                <HeroSection
                    backgroundImage={customBackgroundImage}
                    heading={customHeading}
                    subheading={customSubheading}
                    style={customHeroStyle}
                />
                <section className="google-form-section">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdrvN6Y0fHwIRH5Yu3FupTOhG7ZAPmeSPV3Tmr6oJsioKLr1Q/viewform?embedded=true"
                        title="google form invitation"
                    ></iframe>
                </section>
            </main>
            <FooterBar />
        </div>
    );
};

export default RSVP;
