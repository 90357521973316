// src/components/PhotoCarousel.tsx
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './PhotoCarousel.css'

interface PhotoCarouselProps {
  images: string[]; // Array of image URLs
}

const PhotoCarousel: React.FC<PhotoCarouselProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  const handlers = useSwipeable({
    onSwipedRight: prevImage,
    onSwipedLeft: nextImage,
  });

  return (
    <section className="photo-carousel-section" {...handlers}>
      <div className="photo-carousel-container">
        <div className="photo-carousel-image">
          <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
        </div>
        <div className="photo-carousel-arrows">
          <button className="prev-arrow" onClick={prevImage}>
            &#8249;
          </button>
          <button className="next-arrow" onClick={nextImage}>
            &#8250;
          </button>
        </div>
      </div>
      <div className="photo-carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => setCurrentImageIndex(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default PhotoCarousel;
