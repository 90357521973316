import React from 'react';
import "./App.css"
import {Routes, Route, HashRouter}  from 'react-router-dom';
import JesusValeriaWeddingHomePage from './pages/weddings/JesusValeriaWedding/HomePage';
import JesusValeriaWeddingRSVP from './pages/weddings/JesusValeriaWedding/RSVP';
import KarenJerryWeddingHomePage from './pages/weddings/KarenJerryWedding/HomePage';
import KarenJerryWeddingRSVP from './pages/weddings/KarenJerryWedding/RSVP';
import NotFound from './components/NotFound/NotFound';

function App() {
  return (
    <div className="App">
    <HashRouter>
      <Routes>
        <Route path="/JesusValeriaWedding/" Component={JesusValeriaWeddingHomePage} />
        <Route path="/JesusValeriaWedding/RSVP/" Component={JesusValeriaWeddingRSVP} />
        <Route path="/KarenJerryWedding/" Component={KarenJerryWeddingHomePage} />
        <Route path="/KarenJerryWedding/RSVP/" Component={KarenJerryWeddingRSVP} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
    </div>
  );
}

export default App;
