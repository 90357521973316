// src/components/NavigationBar.tsx
import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./ButtonRSVPSection.css"

interface ButtonRSVPSectionStyle {
    buttonColor?: React.CSSProperties;
}

interface ButtonRSVPSectionProps {
    pageNavigationLinks: { to: string; label: string },
    styles?: ButtonRSVPSectionStyle;
}

const ButtonRSVPSection: React.FC<ButtonRSVPSectionProps> = ({ pageNavigationLinks, styles }) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <section className="button-rsvp-section">
            <NavLink
                to={pageNavigationLinks.to}
                onClick={scrollToTop}
                style={styles?.buttonColor}
            >
                <button className="button-rsvp-item" style={styles?.buttonColor}>{pageNavigationLinks.label}</button>
            </NavLink>
        </section>
    );
};

export default ButtonRSVPSection;
