import React from "react";
import "../../../global_styles/HomePage.css";
import HeroSectionWithClock from "../../../components/HeroSectionWithClock/HeroSectionWithClock";
import DynamicTextSection from "../../../components/DynamicTextSection/DynamicTextSection";
import LocationSection from "../../../components/LocationSection/LocationSection";
import ImageSection from "../../../components/ImageSection/ImageSection";
import GiftSection from "../../../components/GiftSection/GiftSection";
import WeddingText from "../../../components/WeddingText/WeddingText";
import PhotoCarousel from "../../../components/PhotoCarousel/PhotoCarousel";
import NavigationBar from "../../../components/NavigationBar/NavigationBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import LodgingSection from "../../../components/LodgingSection/LodgingSection";
import DressSection from "../../../components/DressSection/DressSection";

const HomePage: React.FC = () => {
    //NavigationBar
    const customNavigationLinks = [
        { to: "/JesusValeriaWedding/", label: "Inicio" },
        { to: "/JesusValeriaWedding/RSVP/", label: "RSVP" },
    ];
    const customSectionLinks = [
        { to: "#Ubicacion", label: "Ubicación" },
        { to: "#Hospedaje", label: "Hospedaje" },
        { to: "#Regalos", label: "Regalos" },
        { to: "#Vestimenta", label: "Vestimenta" },
        { to: "#Galeria", label: "Galería" },
    ];
    const customNavigationStyles = {
        navBarColor: {
            backgroundColor: "#8d99ae",
        },
        navBarActiveColor: {
            backgroundColor: "rgb(255,255,255,0.3)",
        },
        navBarItemPersonalized: {
            color: "white",
            textShadow: "-1px 1px 8px rgba(0, 0, 0, 0.4)"
        }
    };
    //HeroSectionWithClock
    const customHeroImage = require("../../../images/weddings/JesusValeriaWedding/image2.jpeg");
    const customHeroHeading = "Jesus & Valeria";
    const customHeroSubheading = "04 Octubre 2025";
    const customHeroTargetDate = "2025-10-04T23:59:59";
    const customHeroStyle = {
        heroHeadingStyle: {
            fontFamily: "Malibu Ring",
            fontWeight: "100"
        },
        heroSubtextStyle: {
            fontFamily: "didot"
        },
        heroClockStyle: {
            fontFamily: "didot",
        }
    };
    //WeddingText - Biblic Phrase
    const customWeddingTextBiblicPhraseHeader =
    "“Siempre humildes y amables, pacientes, tolerantes unos con otros en amor.”"
    const customWeddingTextBiblicPhraseSubheader = "Efesios 4:2"
    const customWeddingTextBiblicPhraseStyle = {
        headingStyle: {
            color: "#ffffff",
            fontFamily: "Cormorant Garamond",
            fontSize: "1.5rem",
            fontWeight: "500"
        },
        subtitleStyle: {
            fontFamily: "Cormorant Garamond",
            color: "#ffffff ",
            fontSize: "1.4rem"
        },
        sectionStyle: {
            backgroundColor: "#8d99ae"
        },
    };
    const customWeddingTextStyleID = {
        headingStyle: {
            color: "#ffffff",
            fontFamily: "Cormorant SC Regular",
            textTransform: "uppercase" as const,
            letterSpacing: "10px",
            fontWeight: "bold"
        },
        subtitleStyle: {
            color: "#ffffff"
        },
        sectionStyle: {
            backgroundColor: "#8d99ae"
        },
    };
    // DynamicTextSection
    const customDynamicTextHeading = "Con la bendición de Dios y nuestros padres"
    const customDynamicText = [
        {
            parent1: 'Delia Micaela Gonzalez Robledo',
            parent2: 'Otoniel Torres Díaz',
        },
        {
            parent1: 'Gustavo Alonso Valdez Dominguez',
            parent2: 'Paola Cascajares Fernandez',
        }
    ];
    const customDynamicTextStyle = {
        containerStyle: {
            color: "#000000"
        },
        headingStyle: {
            color: "#000000",
            fontFamily: "Cormorant Garamond",
            letterSpacing: "5px",
        },
        firstFamilyStyle: {
            color: "#000000",
            fontFamily: "Cormorant Garamond",
            letterSpacing: "1px",
            fontWeight: "500",
            whiteSpace:"nowrap" as const
        },
        secondFamilyStyle: {
            color: "#000000",
            fontFamily: "Cormorant Garamond",
            letterSpacing: "1px",
            fontWeight: "500",
            whiteSpace:"nowrap" as const
        }
    }
    //ImageSection
    const customImageSection = require("../../../images/weddings/JesusValeriaWedding/image1.jpeg");
    //LocationSection
    const customLocationImages = [
        require("../../../images/weddings/JesusValeriaWedding/location1.webp"),
        require("../../../images/weddings/JesusValeriaWedding/location2.jpeg")
    ];
    const customLocationHeader = [
        "Parroquia Sagrado Corazón de Jesús",
        "La Casona de Santa Lucía"
    ];
    const customLocationTime = ["6:00 PM", "8:00 PM"];
    const customLocationSubtext = [
        "5 de Mayo, Centro, 64000 Monterrey, N.L.",
        "Juan Ignacio Ramón 906, Centro, 64000 Monterrey, N.L."
    ];
    const customLocationLinks = [
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14383.779018735388!2d-100.3098519!3d25.673126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662958a75132f6f%3A0xa9d82c23fa5b8a63!2sParroquia%20del%20Sagrado%20Coraz%C3%B3n%20de%20Jes%C3%BAs!5e0!3m2!1ses-419!2smx!4v1707442426790!5m2!1ses-419!2smx",
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14384.121366650741!2d-100.3062034!3d25.6702889!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662be2b44913a27%3A0x901523ca789af839!2sLa%20Casona%20de%20Santa%20Lucia!5e0!3m2!1ses-419!2smx!4v1707442506542!5m2!1ses-419!2smx"
    ];
    const customLocationStyle = {
        locationHeadingColor: {
            color: "#000000",
            fontFamily: "Cormorant Garamond",
        },
        locationTimeColor: {
            color: "#000000",
            fontFamily: "Cormorant Garamond",
        },
        locationButtonColor: {
            backgroundColor: "#8d99ae",
            fontFamily: "Cormorant Garamond",
        },
    };
    //LodgingSection
    const customLodgingImage = [
        require("../../../images/weddings/JesusValeriaWedding/lodging1.jpeg"),
        require("../../../images/weddings/JesusValeriaWedding/lodging2.jpeg")
    ];
    const customLodgingHeader = [
        "Hoteles Antigua - Santa Lucía",
        "Hoteles Antigua - Casona Allende",
    ];
    const customLodgingSubtext = [
        "Usa el código de reservación #JESUSYVALERIA",
        "Usa el código de reservación #JESUSYVALERIA"
    ];
    const customlinks = [
        "https://www.caminoreal.com/caminoreal/camino-real-monterrey",
        "https://safihotel.com/",
    ];
    //GiftSection
        const customGiftImage = [
            require("../../../images/weddings/JesusValeriaWedding/gift1.jpeg"),
        ];
        const customGiftHeader = [
            "Sobre",
        ];
        const customGiftSubtext = [
            "Que nos acompañes en este gran día es el mejor regalo para nosotros, pero si deseas darnos un obsequio, aquí te dejamos nuestra sugerencia",
        ];
        const customGiftLinks = [
            ""
        ];
    //DressCode
    const customDressCodeImage = [
        require("../../../images/weddings/JesusValeriaWedding/dresscode1.png"),
        require("../../../images/weddings/JesusValeriaWedding/dresscode2.png"),
    ];
    const customDressCodeHeader = [
        "",
    ];
    const customDressCodeSubtext = [
        "",
    ];
    const customDressCodeLinks = [
        ""
    ];
    //PhotoCarousel
    const carouselImages = [
        require("../../../images/weddings/JesusValeriaWedding/image1.jpeg"),
        require("../../../images/weddings/JesusValeriaWedding/image2.jpeg"),
        require("../../../images/weddings/JesusValeriaWedding/image3.jpeg")
    ];
    return (
        <div className="home-page">
            <main>
                <NavigationBar
                    pageNavigationLinks={customNavigationLinks}
                    scrollToSectionLinks={customSectionLinks}
                    logoSrc=""
                    styles={customNavigationStyles}
                />
                <HeroSectionWithClock
                    backgroundImage={customHeroImage}
                    heading={customHeroHeading}
                    subheading={customHeroSubheading}
                    targetDate={customHeroTargetDate}
                    styles={customHeroStyle}
                />
                <WeddingText
                id={""}
                heading={customWeddingTextBiblicPhraseHeader}
                subtitle={customWeddingTextBiblicPhraseSubheader}
                styles={customWeddingTextBiblicPhraseStyle}
                />
                <DynamicTextSection
                heading={customDynamicTextHeading}
                content={customDynamicText}
                styles={customDynamicTextStyle}
                />
                <ImageSection
                backgroundImage={customImageSection}
                />
                <WeddingText
                    id={"Ubicacion"}
                    heading="Ubicación"
                    subtitle=""
                    styles={customWeddingTextStyleID}
                />
                <LocationSection
                    images={customLocationImages}
                    header={customLocationHeader}
                    time={customLocationTime}
                    subtext={customLocationSubtext}
                    links={customLocationLinks}
                    style={customLocationStyle}
                />
                <WeddingText
                    id="Hospedaje"
                    heading="Hospedaje"
                    subtitle=""
                    styles={customWeddingTextStyleID}
                />
                <LodgingSection
                    images={customLodgingImage}
                    header={customLodgingHeader}
                    subtext={customLodgingSubtext}
                    links={customlinks}
                />
                <WeddingText
                    id="Regalos"
                    heading="Regalos"
                    subtitle=""
                    styles={customWeddingTextStyleID}
                />
                <GiftSection
                    images={customGiftImage}
                    header={customGiftHeader}
                    subtext={customGiftSubtext}
                    links={customGiftLinks}
                />
                <WeddingText
                    id="Vestimenta"
                    heading="Vestimenta"
                    subtitle=""
                    styles={customWeddingTextStyleID}
                />
                <DressSection
                    title="Etiqueta"
                    images={customDressCodeImage}
                    header={customDressCodeHeader}
                    subtext={customDressCodeSubtext}
                    links={customDressCodeLinks}
                />
                <WeddingText
                    id="Galeria"
                    heading="Galería"
                    subtitle=""
                    styles={customWeddingTextStyleID}
                />
                <PhotoCarousel images={carouselImages} />
            </main>
            <FooterBar />
        </div>
    );
};

export default HomePage;
