import React from "react";
import "./HeroSection.css";

interface HeroSectionStyle {
    heroHeadingStyle?: React.CSSProperties;
    heroSubtextStyle?: React.CSSProperties;
}

interface HeroSectionProps {
  backgroundImage: string;
  heading: string;
  subheading: string;
  style?: HeroSectionStyle

}

const HeroSection: React.FC<HeroSectionProps> = ({backgroundImage, heading, subheading, style}) => {
  const sectionBackground = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <section className="hero-section" style={sectionBackground}>
      <div className="hero-content">
        <h1 className="hero-heading" style={style?.heroHeadingStyle}>
            {heading}
        </h1>
        <p className="hero-subheading" style={style?.heroSubtextStyle}>{subheading}</p>
      </div>
    </section>
  );
};

export default HeroSection;
