// src/components/LodgingSection.tsx
import React from "react";
import "./LodgingSection.css";

interface LodgingSectionStyles {
    lodgingHeadingStyle?: React.CSSProperties;
    lodgingSubtextStyle?: React.CSSProperties;
}

interface LodgingSectionProps {
    images: string[];
    header: string[];
    subtext: string[];
    links: string[];
    style?: LodgingSectionStyles;
}
const LodgingSection: React.FC<LodgingSectionProps> = ({ images, header, subtext, links, style }) => {
    const handleImageClick = (link: string) => {
        window.location.href = link;
    };
    return (
        <section className="lodging-section">
            {images.map((image, index) => (
                <div className="lodging-container ">
                    <div className="lodging-picture-text">

                        <img src={image} alt={`Image ${index + 1}`}
                            onClick={() => links[index] && handleImageClick(links[index])} // Check if link1 is defined
                            className={links[index] ? 'image-with-link' : 'image'} // Apply different class when there's a link
                            style={links[index] ? { cursor: 'pointer' } : undefined} // Apply cursor style conditionally
                        />
                        <h1 style={style?.lodgingHeadingStyle}>{header[index]}</h1>
                        <p style={style?.lodgingSubtextStyle}>{subtext[index]}</p>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default LodgingSection;
