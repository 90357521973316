// src/components/Footer.tsx
import React from 'react';
import './FooterBar.css'

const FooterBar: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Say Yes Digital. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default FooterBar;
