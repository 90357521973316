// src/components/GiftSection.tsx
import React from "react";
import "./GiftSection.css";

interface GiftSectionStyles {
    giftHeadingStyle?: React.CSSProperties;
    giftSubtextStyle?: React.CSSProperties;
}

interface GiftSectionProps {
    images: string[];
    header: string[];
    subtext: string[];
    links: string[];
    style?: GiftSectionStyles;
}
const GiftSection: React.FC<GiftSectionProps> = ({ images, header, subtext, links, style }) => {
    const handleImageClick = (link: string) => {
        window.location.href = link;
    };
    return (
        <section className="gift-section">
            {images.map((image, index) => (
                <div className="gift-container ">
                    <div className="gift-picture-text">
                    <p style={style?.giftSubtextStyle}>{subtext[index]}</p>
                        <img src={image} alt={`Image ${index + 1}`}
                            onClick={() => links[index] && handleImageClick(links[index])} // Check if link1 is defined
                            className={links[index] ? 'image-with-link' : 'image'} // Apply different class when there's a link
                            style={links[index] ? { cursor: 'pointer' } : undefined} // Apply cursor style conditionally
                        />
                        <h1 style={style?.giftHeadingStyle}>{header[index]}</h1>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default GiftSection;
