import React, { useState, useEffect } from "react";
import "./HeroSectionWithClock.css";

interface HeroSectionWithClockStyle {
    heroHeadingStyle?: React.CSSProperties;
    heroSubtextStyle?: React.CSSProperties;
    heroClockStyle?: React.CSSProperties;
}

interface HeroSectionWithClockProps {
    backgroundImage: string;
    heading: string;
    subheading: string;
    targetDate: string;
    styles?: HeroSectionWithClockStyle;
}

const HeroSectionWithClock: React.FC<HeroSectionWithClockProps> = ({ backgroundImage, heading, subheading, targetDate, styles }) => {
    const sectionBackground = {
        backgroundImage: `url(${backgroundImage})`,
    };

    const targetDateObj = new Date(targetDate).getTime();
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    // Function to calculate the time remaining and update state
    function calculateTimeRemaining() {
        const now = new Date().getTime();
        const timeRemaining = targetDateObj - now;

        if (timeRemaining <= 0) {
            return;
        }

        const dayValue = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hourValue = Math.floor(
            (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minuteValue = Math.floor(
            (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        const secondValue = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        setDays(dayValue);
        setHours(hourValue);
        setMinutes(minuteValue);
        setSeconds(secondValue);
    }

    useEffect(() => {
        calculateTimeRemaining();
        const interval = setInterval(calculateTimeRemaining, 1000); // Update every second

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <section className="hero-section-with-clock" style={sectionBackground}>
            <div className="hero-content-with-clock">
                <h1
                    className="hero-heading-with-clock"
                    style={styles?.heroHeadingStyle}
                >
                    {heading}
                </h1>
                <p
                    className="hero-subheading-with-clock"
                    style={styles?.heroSubtextStyle}
                >
                    {subheading}
                </p>
                <div className="timestamp-container-hero">
                    <div className="timestamp-block-hero">
                        <div>
                            <span
                                className="timestamp-value-hero"
                                style={styles?.heroClockStyle}
                            >
                                {days}
                            </span>
                        </div>
                        <span
                            className="timestamp-label-hero"
                            style={styles?.heroClockStyle}
                        >
                            Días
                        </span>
                    </div>
                    <div className="timestamp-block-hero">
                        <div>
                            <span
                                className="timestamp-value-hero"
                                style={styles?.heroClockStyle}
                            >
                                {hours}
                            </span>
                        </div>
                        <span
                            className="timestamp-label-hero"
                            style={styles?.heroClockStyle}> Horas</span>
                    </div>
                    <div className="timestamp-block-hero">
                        <div>
                            <span
                                className="timestamp-value-hero"
                                style={styles?.heroClockStyle}
                            >{minutes}</span>
                        </div>
                        <span
                            className="timestamp-label-hero"
                            style={styles?.heroClockStyle}
                        > Minutos</span>
                    </div>
                    <div className="timestamp-block-hero">
                        <div>
                            <span
                                className="timestamp-value-hero"
                                style={styles?.heroClockStyle}
                            >{seconds}</span>
                        </div>
                        <span
                            className="timestamp-label-hero"
                            style={styles?.heroClockStyle}
                        > Segundos</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSectionWithClock;
