import React from "react";
import "../../../global_styles/HomePage.css";
import HeroSectionWithClock from "../../../components/HeroSectionWithClock/HeroSectionWithClock";
import LocationSection from "../../../components/LocationSection/LocationSection";
import GiftSection from "../../../components/GiftSection/GiftSection";
import WeddingText from "../../../components/WeddingText/WeddingText";
import DynamicTextSection from "../../../components/DynamicTextSection/DynamicTextSection";
import ImageSection from "../../../components/ImageSection/ImageSection";
import PhotoCarousel from "../../../components/PhotoCarousel/PhotoCarousel";
import NavigationBar from "../../../components/NavigationBar/NavigationBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import LodgingSection from "../../../components/LodgingSection/LodgingSection";
import DressSection from "../../../components/DressSection/DressSection";
import ButtonRSVPSection from "../../../components/ButtonRSVPSection/ButtonRSVPSection";

const HomePage: React.FC = () => {
    //NavigationBar
    const customNavigationLinks = [
        { to: "/KarenJerryWedding/", label: "Inicio" },
        { to: "/KarenJerryWedding/RSVP/", label: "RSVP" },
    ];
    const customSectionLinks = [
        { to: "#Ubicacion", label: "Ubicación" },
        { to: "#Hospedaje", label: "Hospedaje" },
        { to: "#Regalos", label: "Regalos" },
        { to: "#Vestimenta", label: "Vestimenta" },
        { to: "#Galeria", label: "Galería" },
    ];
    const customNavigationStyles = {
        navBarColor: {
            backgroundColor: "#765830",
        },
        navBarActiveColor: {
            backgroundColor: "rgb(255,255,255,0.3)",
        },
        navBarItemPersonalized: {
            color: "white",
            textShadow: "-1px 1px 8px rgba(0, 0, 0, 0.4)"
        }
    };
    //HeroSectionWithClock
    const customHeroImage = require("../../../images/weddings/KarenJerryWedding/hero-photo.jpeg");
    const customHeroHeading = "Karen & Jerry";
    const customHeroSubheading = "11 Marzo 2024";
    const customHeroTargetDate = "2024-03-11T23:59:59";
    const customHeroStyle = {
        heroHeadingStyle: {
            fontFamily: "Malibu Ring",
            fontWeight: "100"
        },
        heroSubtextStyle: {
            fontFamily: "didot"
        },
        heroClockStyle: {
            fontFamily: "didot",
        }
    };

    //WeddingText - Biblic Phrase
    const customWeddingTextBiblicPhraseHeader =
        "“El amor es paciente, es bondadoso. El amor no es envidioso ni jactancioso ni orgulloso. No se comporta con rudeza, no es egoísta, no se enoja fácilmente, no guarda rencor.”"
    const customWeddingTextBiblicPhraseSubheader = "- 1 Corintios 13:4-5"
    const customWeddingTextBiblicPhraseStyle = {
        headingStyle: {
            color: "#765830",
            fontFamily: "Cormorant Garamond",
            fontSize: "1.5rem",
            fontWeight: "500"
        },
        subtitleStyle: {
            fontFamily: "Cormorant Garamond",
            color: "#765830",
            fontSize: "1.4rem"
        },
        sectionStyle: {
            backgroundColor: "#F9F5F2"
        },
    };
    const customWeddingTextStyleID = {
        headingStyle: {
            color: "#765830",
            fontFamily: "Cormorant SC Regular",
            textTransform: "uppercase" as const,
            letterSpacing: "10px",
            fontWeight: "bold"
        },
        subtitleStyle: {
            color: "#765830"
        },
        sectionStyle: {
            backgroundColor: "#F9F5F2"
        },
    };
    // DynamicTextSection
    const customDynamicTextHeading = "Con la bendición de Dios y nuestros padres"
    const customDynamicText = [
        {
            parent1: 'Sarah Madueño',
            parent2: 'Jeraldo delgado',
        },
        {
            parent1: 'Delia Gonzalez Robledo',
            parent2: 'Otoniel Torres Díaz',
        }
    ];
    const customDynamicTextStyle = {
        containerStyle: {
        },
        headingStyle: {
            color: "#765830",
            fontFamily: "Cormorant Garamond",
            letterSpacing: "5px",
        },
        firstFamilyStyle: {
            color: "#765830",
            fontFamily: "Cormorant Garamond",
            letterSpacing: "1px",
            fontWeight: "500",
            whiteSpace:"nowrap" as const
        },
        secondFamilyStyle: {
            color: "#765830",
            fontFamily: "Cormorant Garamond",
            letterSpacing: "1px",
            fontWeight: "500",
            whiteSpace:"nowrap" as const
        }
    }
    //ImageSection
    const customImageSection = require("../../../images/weddings/KarenJerryWedding/image9.jpeg");
    //WeddingText - Uniremos nuestras vidas
    const customWeddingText = "Uniremos nuestras vidas en matrimonio y tenemos el honor de invitarte a ser parte de esta celebración"
    const customWeddingTextStyle2 = {
        headingStyle: {
            color: "#765830",
            fontFamily: "Cormorant Garamond",
            fontSize: "1rem",
            textTransform: "uppercase" as const,
            letterSpacing: "5px"
        },
        subtitleStyle: {
            fontFamily: "Cormorant Garamond",
            color: "#765830",
            fontSize: "1.4rem"
        },
        sectionStyle: {
            backgroundColor: "white"
        },
    };
    //LocationSection
    const customLocationImages = [
        require("../../../images/weddings/KarenJerryWedding/location1.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/location2.jpeg")
    ];
    const customLocationHeader = [
        "Iglesia Corpus Christi",
        "Camino Real"
    ];
    const customLocationTime = ["6:00 PM", "8:00 PM"];
    const customLocationSubtext = [
        "Ojos de Santa Lucía 275, Contry, 64860 Monterrey, N.L.",
        "Av. Diego Rivera 2492, Valle Oriente, 66200 San Pedro Garza García, N.L."
    ];
    const customLocationLinks = [
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.030224209722!2d-100.27964512307464!3d25.637122377430448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bf99577dc651%3A0xce8ae92b39c1c1c9!2sIglesia%20Corpus%20Christi!5e0!3m2!1ses-419!2smx!4v1696099233856!5m2!1ses-419!2smx",
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28775.31743043208!2d-100.29920175504483!3d25.64095711074516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866296ad9bd350fb%3A0xffa23a397f202f8!2sCamino%20Real%20Monterrey!5e0!3m2!1ses-419!2smx!4v1696099491209!5m2!1ses-419!2smx"
    ];
    const customLocationStyle = {
        locationHeadingColor: {
            color: "#765830",
        },
        locationTimeColor: {
            color: "#765830",
        },
        locationButtonColor: {
            backgroundColor: "rgb(143,126,79)"
        },
    };
    //LodgingSection
    const customLodgingStyle = {
        lodgingHeadingStyle: {
            fontFamily: "times",
            color: "#765830",
        },
        lodgingSubtextStyle: {
            fontFamily: "times"
        }
    };
    const customLodgingImage = [
        require("../../../images/weddings/KarenJerryWedding/lodging1.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/lodging2.webp")
    ];
    const customLodgingHeader = [
        "Camino Real",
        "SAFI",
    ];
    const customLodgingSubtext = [
        "Usa el código de reservación #KARENYJERRY",
        "Usa el código de reservación #KARENYJERRY"
    ];
    const customlinks = [
        "https://www.caminoreal.com/caminoreal/camino-real-monterrey",
        "https://safihotel.com/",
    ];
    //GiftSection
    const customGiftStyle = {
        giftHeadingStyle: {
            fontFamily: "didot",
        },
        giftSubtextStyle: {
            fontFamily: "didot"
        }
    };
    const customGiftImage = [
        require("../../../images/weddings/KarenJerryWedding/gift1.jpeg"),
    ];
    const customGiftHeader = [
        "Sobre",
    ];
    const customGiftSubtext = [
        "Que nos acompañes en este gran día es el mejor regalo para nosotros, pero si deseas darnos un obsequio, aquí te dejamos nuestra sugerencia",
    ];
    const customGiftLinks = [
        ""
    ];
    //DressCode
    const customDressStyle = {
        dressTitleStyle: {
            fontFamily: "didot",
        },
        dressHeadingStyle: {
            fontFamily: "times",
        },
        dressSubtextStyle: {
            fontFamily: "didot"
        }
    };
    const customDressCodeImage = [
        require("../../../images/weddings/KarenJerryWedding/dresscode1.png"),
        require("../../../images/weddings/KarenJerryWedding/dresscode2.png"),
    ];
    const customDressCodeHeader = [
        "",
    ];
    const customDressCodeSubtext = [
        "",
    ];
    const customDressCodeLinks = [
        ""
    ];
    //PhotoCarousel
    const carouselImages = [
        require("../../../images/weddings/KarenJerryWedding/hero-photo.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/image1.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/image2.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/image4.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/image5.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/image7.jpeg"),
        require("../../../images/weddings/KarenJerryWedding/image8.jpeg")
    ];
    //ButtonSection
    const customButtonLinks = { to: "/KarenJerryWedding/RSVP/", label: "RSVP" }
    const customButtonStyle = {
        buttonColor: {
            backgroundColor: "#765830"
        }
    }

    return (
        <div className="home-page">
            <NavigationBar
                pageNavigationLinks={customNavigationLinks}
                scrollToSectionLinks={customSectionLinks}
                logoSrc=""
                styles={customNavigationStyles}
            />
            <HeroSectionWithClock
                backgroundImage={customHeroImage}
                heading={customHeroHeading}
                subheading={customHeroSubheading}
                targetDate={customHeroTargetDate}
                styles={customHeroStyle}
            />
            <WeddingText
                id={""}
                heading={customWeddingTextBiblicPhraseHeader}
                subtitle={customWeddingTextBiblicPhraseSubheader}
                styles={customWeddingTextBiblicPhraseStyle}
            />
            <DynamicTextSection
                heading={customDynamicTextHeading}
                content={customDynamicText}
                styles={customDynamicTextStyle}
            />
            <ImageSection
                backgroundImage={customImageSection}
            />
            <WeddingText
                id={""}
                heading={customWeddingText}
                subtitle=""
                styles={customWeddingTextStyle2}
            />
            <WeddingText
                id={"Ubicacion"}
                heading="Ubicación"
                subtitle=""
                styles={customWeddingTextStyleID}
            />
            <LocationSection
                images={customLocationImages}
                header={customLocationHeader}
                time={customLocationTime}
                subtext={customLocationSubtext}
                links={customLocationLinks}
                style={customLocationStyle}
            />
            <WeddingText
                id="Hospedaje"
                heading="Hospedaje"
                subtitle=""
                styles={customWeddingTextStyleID}
            />
            <LodgingSection
                images={customLodgingImage}
                header={customLodgingHeader}
                subtext={customLodgingSubtext}
                links={customlinks}
                style={customLodgingStyle}
            />
            <WeddingText
                id="Regalos"
                heading="Regalos"
                subtitle=""
                styles={customWeddingTextStyleID}
            />
            <GiftSection
                images={customGiftImage}
                header={customGiftHeader}
                subtext={customGiftSubtext}
                links={customGiftLinks}
                style={customGiftStyle}
            />
            <WeddingText
                id="Vestimenta"
                heading="Vestimenta"
                subtitle=""
                styles={customWeddingTextStyleID}
            />
            <DressSection
                title="Etiqueta"
                images={customDressCodeImage}
                header={customDressCodeHeader}
                subtext={customDressCodeSubtext}
                links={customDressCodeLinks}
                style={customDressStyle}
            />
            <WeddingText
                id="Galeria"
                heading="Galería"
                subtitle=""
                styles={customWeddingTextStyleID}
            />
            <PhotoCarousel images={carouselImages} />
            <WeddingText
                id=""
                heading="RSVP"
                subtitle=""
                styles={customWeddingTextStyleID}
            />
            <WeddingText
                id=""
                heading=""
                subtitle="Confirma tu asistencia"
                styles={customWeddingTextStyle2}
            />
            <ButtonRSVPSection
                pageNavigationLinks={customButtonLinks}
                styles={customButtonStyle}
            />
            <FooterBar />
        </div>
    );
};

export default HomePage;
